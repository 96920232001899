<template>
    <div class="main">
        <div class="main-head">
            <div class="left">
                <img src="@/assets/img/indexLogo.png" alt="" />
                <div class="youxinlixin">
                    <span class="jihua">优信立信计划</span>
                    <span class="url">youxin-gov.cn</span>
                </div>
            </div>
            <div class="right"></div>
        </div>
        <div class="compant-info">
            <div :class="['compant-name',{'compant-name-long':componeyInfo.name.length>15}]">{{ componeyInfo.name }}</div>
            <div class="jy-time">{{ componeyInfo.desc }}</div>
            <div class="check">
                <template v-for="(item, index) of checkList">
                    <div class="check-input" :key="index">
                        <img
                            v-if="!item.val"
                            class="check-value"
                            src="@/assets/img/check-no.png"
                            alt=""
                        />
                        <img
                            v-else
                            class="check-value"
                            src="@/assets/img/check-yes.png"
                            alt=""
                        />
                        <span>{{ item.text }}</span>
                    </div>
                </template>
            </div>
        </div>
        <div class="plan">
            <div :class="['comp-name',planCompantNameLen(componeyInfo.name)]">{{ componeyInfo.name }}</div>
            <div class="title">获得“立信企业”荣誉牌匾</div>
            <div class="paibian"></div>
            <div class="commitment">
                <img
                    @click="commitment = true"
                    v-show="!commitment"
                    src="@/assets/img/radio-no.png"
                    alt=""
                />
                <img
                    @click="commitment = false"
                    v-show="commitment"
                    src="@/assets/img/radio-yes.png"
                    alt=""
                />
                <div class="book">
                    同意签署<span @click="lookFun()">《守信承诺书》</span>
                </div>
            </div>
            <div @click="receiveFun()" class="lingqu-btn"></div>
        </div>
        <div class="interests">
            <div class="title">信用档案 - 让信用看得见</div>
            <div class="content">
                为企业创建专属信用档案，便于快速分享 被分享者点击可查看企业荣誉
            </div>
            <div class="quanyi-img"></div>
            <div class="quanyi-text">
                信用档案包含主题基本信息、守信承诺书、
                平台监控、消费者提交等内容
            </div>
            <div @click="receiveFun()" class="quanyi-btn">
                <span>立即获取权益</span>
            </div>
        </div>
        <div class="plan-two">
            <div class="title">官方新闻稿 - 传播信用形象</div>
            <div class="content">企业专属图文并茂新闻稿，一键分享到朋友圈</div>
            <div class="quanyi-two-img"></div>
            <div @click="receiveFun()" class="quanyi-btn">
                <span>立即获取权益</span>
            </div>
        </div>
        <!-- 关于我们 -->
        <aboutUsC />
        <!-- 收货弹窗 -->
        <shippingC @submit="submitFun" ref="shippingRef" />
        <!-- 守信承诺书 -->
        <commitmentC :book="book" ref="commitmentRef" />
    </div>
</template>

<script>
import commitmentC from "@/components/commitment";
import shippingC from "@/components/shipping";
import aboutUsC from "@/components/aboutUs";
import {
    orderInfoApi,
    indexApi,
    saveAddressApi,
    openidApi,
} from "@/api/index.js";
export default {
    name: "index",
    components: {
        aboutUsC,
        shippingC,
        commitmentC,
    },
    data() {
        return {
            checkList: [
                {
                    val: true,
                    text: "无失信被执行",
                },
                {
                    val: true,
                    text: "无经营异常",
                },
                {
                    val: true,
                    text: "无负面舆情",
                },
            ],
            commitment: true,
            cid: "52",
            componeyInfo: {
                desc: "自 XX 年成立，经营期间",
                name: "公司 / 个体户名称 XXXXXX",
                img: "",
            },
            book: {
                title: "",
                content: "",
                name: "",
            },
            code: null,
        };
    },
    created() {
        let self = this;
        if(self.$route.params.cid)self.cid = self.$route.params.cid;
        // console.log('****************',self.cid,self.util)
        // console.log(self.$store.state.is_weixin_app,self.$route.query.code)
        if (self.$store.state.is_weixin_app) {
            self.code = self.$route.query.code;
            if (!self.code) {
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe225cccbbb5eaac6&redirect_uri=${encodeURIComponent('https://youxin-gov.cn/'+self.cid)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
            } else {
                if (self.cid) {
                    self.openIdFun();
                    self.orderInfoApiFun();
                } else {
                    self.$toast("企业ID为空");
                }
            }
        } else {
            if (self.cid) {
                self.orderInfoApiFun();
            } else {
                self.$toast("企业ID为空");
            }
        }
    },
    methods: {
        openIdFun() {
            openidApi({
                cid: this.cid,
                wxcode: this.code,
            }).then((res) => {
                console.log(res);
            });
        },
        orderInfoApiFun() {
            let self = this;
            // console.log("323");
            orderInfoApi(self.cid).then((res) => {
                if(res.code == 200){
                    if(res.data.ispay) {
                        self.util.skipUrl('/succeed/'+self.cid,{},'replace');
                    } else {
                        self.indexApiFun();                        
                    }
                }else{
                    self.$toast(res.msg || '服务器请求错误')
                }               
            });
        },
        indexApiFun() {
            let self = this;            
            indexApi(self.cid).then((res) => {
                if(res.code == 200){
                    self.componeyInfo.desc = res.data.comp_desc;
                    self.componeyInfo.name = res.data.comp_name;
                    self.$store.commit("CHANGE_COMPANY_NAME", {
                        name: res.data.comp_name,
                    });
                    self.book.name = res.data.comp_name;
                    self.book.title = res.data.shouxin_agreement.title;
                    self.book.content = res.data.shouxin_agreement.info;
                    let info = res.data.shipping_address;
                    if (info) {
                        let option = {
                            name: info.name,
                            phone: info.mobile,
                            provinces:
                                info.pro_code_format +
                                info.city_code_format +
                                info.gov_code_format,
                            site: info.address,
                            provincesCode: [
                                info.pro_code,
                                info.city_code,
                                info.gov_code,
                            ],
                            provincesName: [
                                info.pro_code_format,
                                info.city_code_format,
                                info.gov_code_format,
                            ],
                        };
                        self.setlocalStorageFun(option);
                        self.$refs.shippingRef.getLocaStrogFun();
                    } else {
                        window.localStorage.removeItem("address_yuixin");
                    }
                    let option = self.util.shareData();
                    option.link = `${location.origin}/${self.cid}`;
                    option.title = res.data.comp_name;
                    // console.log(option)
                    //微信分享触发事件
                    self.util.weixinShareFun(option);
                }else{
                    self.$toast(res.msg || '服务器请求错误')
                }                
            });
        },
        //查看守信承诺书
        lookFun() {
            this.$refs.commitmentRef.showFun();
        },
        //点击同意签署才显示选择地址
        receiveFun() {
            if (!this.commitment) return this.$toast("请勾选同意签署,再领取");
            this.$refs.shippingRef.showFun();
        },
        setlocalStorageFun(option) {
            window.localStorage.setItem(
                "address_yuixin",
                JSON.stringify(option)
            );
        },
        //选择完地址后跳转
        submitFun(option) {
            let self = this;
            let data = {
                cid: this.cid,
                name: option.name,
                mobile: option.phone,
                pro_code: option.provincesCode[0],
                city_code: option.provincesCode[1],
                gov_code: option.provincesCode[2],
                address: option.site,
            };
            console.log(data);
            saveAddressApi(data).then((res) => {
                if(res.code == 200){
                    self.setlocalStorageFun(option);
                    self.$router.push({
                        path: "/payment/"+self.cid,
                        query: {
                            cid: self.cid,
                        },
                    });
                }else{
                    self.$toast(res.msg || '服务器请求错误')
                }
            });
        },
        //企业名称长度选择字体大小
        planCompantNameLen(str){
            // console.log(str.length)
            let className = "";
            if(str.length>14 && str.length<17){
                className = "comp-name-12";
            }else if(str.length>16 && str.length<20){
                className = "comp-name-11";
            }else if(str.length>19){
                className = "comp-name-10";
            }
            return className;
        }
    },
};
</script>

<style lang="less" scoped>
.main {
    width: 375px;
    height: 2104px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url("~@/assets/img/indexBag.jpeg") no-repeat;
    background-size: 100% 100%;
    .main-head {
        width: 343px;
        height: 40px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .left {
            display: flex;
            img {
                width: 40px;
                height: 40px;
            }
            .youxinlixin {
                display: flex;
                flex-direction: column;
                margin-left: 6px;
                .jihua {
                    color: #333333;
                    font-size: 16px;
                    font-weight: bold;
                }
                .url {
                    color: #666666;
                    font-size: 11px;
                }
            }
        }
        .right {
            width: 180px;
            height: 35px;
            background: url("~@/assets/img/yunying.png") no-repeat;
            background-size: 100% 100%;
        }
    }
    .compant-info {
        position: relative;
        width: 343px;
        height: 139px;
        background: url("~@/assets/img/company-info.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 21px;
        .compant-name {
            position: absolute;
            top: 25px;
            left: 50%;
            width: 90%;
            text-align: center;
            transform: translateX(-50%);
            font-size: 20px;
            background: linear-gradient(
                180deg,
                #eacba1 0%,
                #c9986f 99.12109375%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 700;
        }
        .compant-name-long{
            top: 20px;
            font-size: 18px;
        }
        .jy-time {
            position: absolute;
            top: 66px;
            left: 50%;
            width: 100%;
            text-align: center;
            transform: translateX(-50%);
            color: #d32e1e;
            font-size: 20px;
            font-weight: 500;
        }
        .check {
            position: absolute;
            top: 104px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 20px;
            display: flex;
            padding: 0 20px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            .check-input {
                display: flex;
                .check-value {
                    width: 20px;
                    height: 20px;
                }
                span {
                    display: flex;
                    align-items: center;
                    color: #666666;
                    font-size: 11px;
                    margin-left: 5px;
                }
            }
        }
    }
    .plan {
        position: relative;
        width: 343px;
        height: 419px;
        background: url("~@/assets/img/plan.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 26px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .comp-name {
            width: 200px;
            position: absolute;
            top: 177px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 13px;
            text-align: center;
        }
        .comp-name-12{
            top: 174px;
            font-size: 12px;
        }
        .comp-name-11{
            top: 172px;
            font-size: 11px;
        }
        .comp-name-10{
            top: 170px;
            font-size: 10px;
        }
        .title {
            font-size: 24px;
            font-weight: 700;
            background: linear-gradient(180deg, #eacba1 0%, #c9986f 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-top: 71px;
        }
        .paibian {
            width: 289px;
            height: 193px;
            background: url("~@/assets/images/paibian-mu.png") no-repeat;
            background-size: 100% 100%;
            margin-top: 19px;
        }
        .commitment {
            width: 100%;
            height: 15px;
            display: flex;
            justify-content: center;
            margin-top: 8px;
            align-items: center;
            img {
                width: 20px;
                height: 20px;
            }
            .book {
                font-size: 11px;
                color: #666666;
                span {
                    color: #0057ff;
                }
            }
        }
        .lingqu-btn {
            width: 298px;
            height: 46px;
            background: url("~@/assets/img/lingqu-btn.png") no-repeat;
            background-size: 100% 100%;
            margin-top: 15px;
        }
    }
    .interests {
        width: 343px;
        height: 597px;
        background: url("~@/assets/img/quanyi-one.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
        .title {
            font-size: 24px;
            font-weight: 700;
            background: linear-gradient(180deg, #eacba1 0%, #c9986f 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-top: 81px;
        }
        .content {
            width: 250px;
            color: #666666;
            text-align: center;
            margin-top: 20px;
        }
        .quanyi-img {
            width: 305px;
            height: 275px;
            background: url("~@/assets/img/quanyi-img.jpg") no-repeat;
            background-size: 100% 100%;
            margin-top: 6px;
        }
        .quanyi-text {
            width: 257px;
            text-align: center;
            color: #666666;
            margin-top: 6px;
        }
        .quanyi-btn {
            width: 296px;
            height: 44px;
            background: url("~@/assets/img/quanyi-btn.png") no-repeat;
            background-size: 100% 100%;
            margin-top: 19px;
            display: flex;
            justify-content: center;
            span {
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
                margin-top: 8px;
            }
        }
    }
    .plan-two {
        width: 343px;
        height: 494px;
        background: url("~@/assets/img/quanyi-two.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 25px 0 22px;
        .title {
            font-size: 24px;
            font-weight: 700;
            background: linear-gradient(180deg, #eacba1 0%, #c9986f 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-top: 71px;
        }
        .content {
            color: #666666;
            text-align: center;
            margin-top: 20px;
        }
        .quanyi-two-img {
            width: 276px;
            height: 238px;
            background: url("~@/assets/img/quanyi-two-img.png") no-repeat;
            background-size: 100% 100%;
            margin-top: 13px;
        }
        .quanyi-btn {
            width: 296px;
            height: 44px;
            background: url("~@/assets/img/quanyi-btn.png") no-repeat;
            background-size: 100% 100%;
            margin-top: 19px;
            display: flex;
            justify-content: center;
            span {
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
                margin-top: 8px;
            }
        }
    }
}
</style>